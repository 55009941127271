import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ISidebarItem } from '../interfaces/ISidebarItem';
import { webRoutes } from '../../../core/constants/routes';
import { AuthService } from '../../../shared/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class SidebarService {
  private items: ISidebarItem[] = [
    {
      title: 'DASHBOARD',
      routerLink: webRoutes.marveler,
      icon: 'assets/icons/dashboard-pink.svg',
    },
    {
      title: 'PROFILE',
      routerLink: webRoutes.profileMarveler,
      icon: 'assets/icons/profile-pink.svg',
    },
    {
      title: 'BOOKINGS',
      routerLink: webRoutes.booking,
      icon: 'assets/icons/booking-pink.svg',
    },
    {
      title: 'PAYMENT',
      routerLink: webRoutes.payment,
      icon: 'assets/icons/payment-pink.svg',
    },
    {
      title: 'FAVORITE COUNSELORS',
      routerLink: webRoutes.counselors,
      icon: 'assets/icons/favorite-counselor-pink.svg',
    },
    {
      title: 'REVIEWS',
      routerLink: webRoutes.reviews,
      icon: 'assets/icons/reviews-pink.svg',
    },
    {
      title: 'GALLERY',
      routerLink: webRoutes.gallery,
      icon: 'assets/icons/camera-pink.svg',
    },
    {
      title: 'SETTINGS',
      routerLink: webRoutes.setting,
      icon: 'assets/icons/setting-pink.svg',
    }
  ];

  private sidebarItems = new BehaviorSubject<ISidebarItem[]>(this.items);
  private logoutIcon = new BehaviorSubject<string>('assets/icons/logout-green.svg');
  private sidebarBackgroundColor = new BehaviorSubject<string>('var(--secondary-color)');
  private titleColor = new BehaviorSubject<string>('var(--secondary-color)');
  private sidebarState = new BehaviorSubject<boolean>(false);

  constructor(private authService: AuthService) {
    this.authService.getUserType().subscribe(userType => {
      if (!userType) {
        userType = localStorage.getItem('userType');
      }
      this.updateSidebarAppearance(userType);
    });
  }

  public get navItems(): Observable<ISidebarItem[]> {
    return this.sidebarItems.asObservable();
  }

  public get logoutIconPath(): Observable<string> {
    return this.logoutIcon.asObservable();
  }

  public get sidebarBgColor(): Observable<string> {
    return this.sidebarBackgroundColor.asObservable();
  }

  public get titleTextColor(): Observable<string> {
    return this.titleColor.asObservable();
  }

  public get getSidebarState(): Observable<boolean> {
    return this.sidebarState.asObservable();
  }

  public setSidebarState(state: boolean) {
    this.sidebarState.next(state);
  }

  public toggleSidebar() {    
    this.sidebarState.next(!this.sidebarState.value);
  }

  private updateSidebarAppearance(userType: string | null) {
    if (userType === 'counselor') {
      this.sidebarItems.next([
        {
          title: 'DASHBOARD',
          routerLink: webRoutes.counselor,
          icon: 'assets/icons/dashboard-green.svg',
        },
        {
          title: 'PROFILE',
          routerLink: webRoutes.profileCounselor,
          icon: 'assets/icons/profile-green.svg',
        },
        {
          title: 'AVAILABILITY',
          routerLink: webRoutes.availability,
          icon: 'assets/icons/availabililty-green.svg',
        },        
        {
          title: 'BOOKINGS',
          routerLink: webRoutes.booking,
          icon: 'assets/icons/booking-green.svg',
        },
        {
          title: 'PAYMENT',
          routerLink: webRoutes.payment,
          icon: 'assets/icons/payment-green.svg',
        },
        {
          title: 'EARNING',
          routerLink: webRoutes.earning,
          icon: 'assets/icons/earning-green.svg',
        },
        // {
        //   title: 'CAREER PATH',
        //   routerLink: webRoutes.counselors,
        //   icon: 'assets/icons/career-path-green.svg',
        // },
        {
          title: 'REVIEWS',
          routerLink: webRoutes.reviews,
          icon: 'assets/icons/reviews-green.svg',
        },
        {
          title: 'GALLERY',
          routerLink: webRoutes.gallery,
          icon: 'assets/icons/camera-green.svg',
        },
        {
          title: 'SETTINGS',
          routerLink: webRoutes.setting,
          icon: 'assets/icons/setting-green.svg',
        }
      ]);
      this.logoutIcon.next('assets/icons/logout-green.svg');
      this.sidebarBackgroundColor.next('var(--secondary-color)');
      this.titleColor.next('var(--secondary-color)');
    } else if (userType === 'marveler') {
      this.sidebarItems.next(this.items);
      this.logoutIcon.next('assets/icons/logout-pink.svg');
      this.sidebarBackgroundColor.next('var(--primary-color)');
      this.titleColor.next('var(--primary-color)');
    } else {
      this.sidebarItems.next(this.items);
      this.logoutIcon.next('assets/icons/logout-pink.svg');
      this.sidebarBackgroundColor.next('var(--primary-color)');
      this.titleColor.next('var(--primary-color)');
    }
  }
}
